import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CloseBorderIcon from '@mui/icons-material/Close';
import CheckBorderIcon from '@mui/icons-material/Check';
import RecommendIcon from '@mui/icons-material/Recommend';
import RemoveIcon from '@mui/icons-material/Remove';
import { useContext } from 'react';
import isDarkMode from '../../utils/contexts/IsDarkMode';
import { useTranslation } from 'react-i18next';


interface PasswordTipsProps{
    result: {
        checked: boolean,
        disabled: boolean,
        color: string,
        icon: string,
        checkedIcon: string,
    }[]
}

export default function PasswordTips( {result}:PasswordTipsProps) {
  const { t } = useTranslation();
  const tipsList= [
            {id:1, text: `${t("changepassword.tip1")}`},
            {id:2, text: `${t("changepassword.tip2")}`},
            {id:3, text: `${t("changepassword.tip3")}`},
            {id:4, text: `${t("changepassword.tip4")}`},
            {id:5, text: `${t("changepassword.tip5")}`},
        ];

  const [checked, setChecked] = React.useState([1]);
  const isDark = useContext(isDarkMode)

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List dense sx={{ maxWidth: 700, width: '90%', bgcolor: isDark? 'divider' : 'inherit', boxShadow: 1, marginTop: 2, marginBottom: 3, marginRight: 0 }}>
      {tipsList.map((value,index) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        if (result[index].icon != "remove") {
          return (
            <ListItem
              key={value.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  //onChange={handleToggle({value.id})}
                  disabled={result[index].disabled}
                  checked={result[index].checked}
                  sx={{color: result[index].color, '&.Mui-checked': {color: result[index].color,},}}
                  icon={ { 'check': <CheckBorderIcon />, 'close': <CloseBorderIcon />, 'recommend': <RecommendIcon />, 'remove': <RemoveIcon />}[result[index].icon] }
                  checkedIcon={ { 'check': <CheckBorderIcon />, 'close': <CloseBorderIcon />, 'recommend': <RecommendIcon />, 'remove': <RemoveIcon />}[result[index].checkedIcon] }
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemText primaryTypographyProps={{ style: {color: isDark? 'white' : 'inherit'} }} id={labelId} primary={`${value.text}`} />
              </ListItemButton>
            </ListItem>
          );
        }
      })}
    </List>
  );
}