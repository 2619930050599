import * as React from 'react';
import { styled, createTheme, ThemeProvider, useThemeProps } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import MuiToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import apptheme from '../../utils/Config'
import { ToggleButton } from '@mui/material';
// import { CustomToggleBanner } from '../../utils/MyLicenseStyle';
import { StyledToggleButtonGroup } from '../../utils/MyLicenseStyle';
import { useContext } from 'react';
import isDarkMode from '../../utils/contexts/IsDarkMode';
import { useTranslation  } from 'react-i18next';

interface StatProp {
  isapply: string,
  setapply: (val: string) => void
};

export default function ColorToggleButton ({ isapply, setapply }: StatProp) {
  // console.log (`Child: start ${isapply}`)
  const isDark : boolean = useContext(isDarkMode);
  const { t } = useTranslation();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    new_stats: string,
  ) => {
    if (new_stats !== null && new_stats != isapply ) {
      setapply(new_stats);
      }
  }

  // const applyButtonColorWeb = isDark ? '#1a638f' : apptheme.palette.primary.main 

  const applyButtonColorWeb = isDark ? '#15c0b0' : apptheme.palette.primary.main 
  const notApplyButtonColorWeb = isDark ? "#b894e5" : "#d82f2f" 
  const paperColorMobile = isDark ? '#515151' : 'inherit'
  
  return (
    <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: "center",
          border: (theme) => `0px solid`,
          flexWrap: 'wrap',
          mt: 2,
          mb: 2,
          bgcolor: {xs: paperColorMobile, sm: paperColorMobile, md: isDark? '#312c2c': '#aaacac' },
          width: { md: 360},
        }}
      >
      <StyledToggleButtonGroup 
        selectedcolor= { isapply === "yes" ? applyButtonColorWeb : notApplyButtonColorWeb }
        selectedfontcolor = {isapply === "yes" ? '#03DAC5' : '#b894e5' }
        value={isapply}
        isdark={isDark.toString()}
        exclusive 
        onChange={handleChange}
        sx= {{ width: `100%`, justifyContent: "center",}}
      > 
        <ToggleButton value="yes" sx={{px: {xs: 3, sm: 10, md:5}}}>{t("button.registered")}</ToggleButton>
        <ToggleButton value="no" sx={{px: {xs: 3, sm: 10, md:4} }}>{t("button.notapply")}</ToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
}
