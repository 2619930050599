import { ThemeProvider, createTheme} from "@mui/material";

export const darkTheme = createTheme({
    palette: {
        error: {
            main: '#f8672f'
        },
        mode: 'dark',
    },
});

// export default darkTheme
export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#005F96',
        },  
        mode: 'light',
    }
})



