import React, { useState, useContext, useEffect } from 'react';
import {Navigate} from 'react-router-dom';
import axios, {AxiosError} from 'axios';
import {Box, Container, Alert, Typography, Grid, InputLabel, MenuItem, FormControl, FormHelperText, Button, CircularProgress} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import isDarkMode from '../utils/contexts/IsDarkMode';
import ErrorMsgDialog from '../components/HandleMsgDialog';
import UserLicenseInfoContext,{ UserLicenseInfo } from '../utils/contexts/UserLicenseInfoContext';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { isUMApp, PageModule } from '@um/js-api';

interface UMAppContextType {
  isApp: boolean;
}

const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};

const checkRight = () => {
  const licenseInfo = useContext(UserLicenseInfoContext);
  if (licenseInfo !== null && licenseInfo.Service.includes('ACCOUNT_CHANGE_PROFILE_LANGUAGE')) {
    // console.log("check right return true")
    return true;
  } else {
    // console.log("check right return false")
    return false;
  }
};


const ChangeProfileLanguage = () => {
  const { t } = useTranslation();
  if (isUMApp()) {
    PageModule.setTitle(`${t("changeprofilelanguage.pagetitle")}`)
  }
  document.title="Change computer room profile language"
  sessionStorage.removeItem("prevPath");
  const isDark = useContext(isDarkMode);
  const [UserLicenseInfo, setUserLicenseInfo] = useState<UserLicenseInfo | null>(null);
  const [curr_lang, setCurrLang] = useState<number>(0);
  const [lang, setLang] = useState(String(curr_lang));
  const [isChange, setIsChange] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [pageloading, setPageLoading] = React.useState(true);
  const [errorMsgDialogOpen, setErrorMsgDialogOpen] = useState(false);
  const [errorMsgDialogTitle, setErrorMsgDialogTitle] = useState("Sorry!");
  const [errorMsgDialogContent, setErrorMsgDialogContent] = useState("The service is temporarily unavailable and please try again later. We apologize for any inconveniences caused.");
  const [needReload, setNeedReload] = useState<boolean>(true);
    
    // const [showProfilelang, setShowProfilelang] = useState<boolean>();

    const lang_map = ['English, 英語', '繁體中文, Tranditional Chinese', '简体中文, Simplified Chinese', '日本語, Japanese']

    useEffect(() => {
      let respData : UserLicenseInfo;
      if (UserLicenseInfo === null && location.pathname !== `${process.env.REACT_APP_CONTEXT_PATH}/error`) {
        axios
          .get(`${process.env.REACT_APP_CONTEXT_PATH}/api/account/get_account_info`)
          .then((resp) => {
            respData=resp.data.data;
            respData['Service'] = (resp.data.role.services);
            setUserLicenseInfo(respData as UserLicenseInfo);
            // console.log(resp.data.role.services)
            if (resp.data.role.services.includes('ACCOUNT_CHANGE_PROFILE_LANGUAGE')) {
              setCurrLang(resp.data.data.ACCOUNT_CHANGE_PROFILE_LANGUAGE_RESULT.language)
              setLang(String(resp.data.data.ACCOUNT_CHANGE_PROFILE_LANGUAGE_RESULT.language))
              setLoading(false)
              setPageLoading(false)
            }
          })
          .catch((error: AxiosError) => {
            console.log(error);
          });

      } 
  }, [UserLicenseInfo]);
    
    const handleChange = (event: SelectChangeEvent) => {
        setLang(event.target.value);
    };    

    useEffect(() => {
        if (curr_lang !== Number(lang)) {
            setIsChange(true)
        } else {
            setIsChange(false)
        };   
    }, [lang]); 

    // console.log("Change user profile language -->>>>>>>")
    // console.log(curr_lang)
    // console.log(UserLicenseInfo?.ACCOUNT_CHANGE_PROFILE_LANGUAGE_RESULT?.language);

    const handleMsgDialogOpen = (title: string, content: string) => {
        setErrorMsgDialogTitle(title);
        setErrorMsgDialogContent(content);
        setErrorMsgDialogOpen(true);
      };
    
      const handleMsgDialogClose = () => {
        setErrorMsgDialogOpen(false);
    };

    const onChnageProfile = async () => {
        if (!isChange) {
          handleMsgDialogOpen(`${t("changeprofilelanguage.optionerr")}`, `${t("changeprofilelanguage.optionerrmsg")}`)
        //   throw new Error('Error: User not checked agree!');
        }
        if (!loading) {
          setLoading(true);
        }
        try {
          const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_CONTEXT_PATH}/api/account/student_change_language`,
            data: {"language": lang},
          });
          // console.log('In apply setting changes Profile')
          //  console.log(response)
          //  console.log(response.status)
          if (response.status === 200) {
            // var result = String(response.data);
            // console.log("response == 200")
            if (response.data.code === 600) {
                // console.log("response code== 600")
              handleMsgDialogOpen(`${t("changeprofilelanguage.errheader")} (${response.data.pcode})`, response.data.data)
            } else {
                handleMsgDialogOpen(`${t("changeprofilelanguage.succ_header")}`, `${t("changeprofilelanguage.succ_msg")}`)
            }
            setLoading(false);
          } else {
            handleMsgDialogOpen(`${t("changeprofilelanguage.errheader")}`, `${t("changeprofilelanguage.updateerrmsg")}`)  
            setLoading(false);
            // throw new Error(`Error: Not able to apply change for student profile language`);
          }
        } catch (error) {
          handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error))
          setLoading(false);
          console.log(error);
        }
    };
   
    // console.log("In page change profile language: " + showProfilelang )
    // console.log("In page change profile language: " + UserLicenseInfo )

    if (!checkRight() && UserLicenseInfo !== null && UserLicenseInfo !== undefined) {
      // console.log("no right to change profile language");
      return <Navigate to="/error" replace />;
    } else {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', mx: 2}}>
          {pageloading && (
                <CircularProgress
                      size={35}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
          )}
          <UserLicenseInfoContext.Provider value={UserLicenseInfo}>
            <Container disableGutters maxWidth="md" sx={{ justifyContent: 'center', alignContent: 'center', mt: 5, display: pageloading ? 'none': 'block'}}>
                <Typography variant='subtitle1' sx={{ pb: 3, color: isDark ? '#eeeeee' : 'inherit'}}>
                {t("changeprofilelanguage.top_statement")}
                </Typography>
                <Grid container>
                        <Grid item>
                        <Typography variant='h5' sx={{fontWeight: 550, color: isDark ? '#eeeeee' : 'inherit'}}>
                            {t("changeprofilelanguage.fieldname")}
                        </Typography>
                        </Grid>
                        <Grid item sx={{ml: {xs: 0, sm:0 , md: 2}, mb: 3}}>
                        <Typography variant='h5' color={isDark? "#03DAC5":"#38761d"} > {lang_map[curr_lang]} </Typography> 
                        </Grid>
                </Grid>
                <Alert severity='warning' sx={{ mb: 2}} >
                  {t("changeprofilelanguage.warning1")}
                </Alert>
                <Alert severity='warning' sx={{ mb: 2}}>
                  {t("changeprofilelanguage.warning2")}
                </Alert>
                <Box width="md" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4}}>
                        <FormControl sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <InputLabel id="lang_selector">{t("changeprofilelanguage.option_label")}</InputLabel>
                            <Select
                                labelId="lang_selector"
                                id="lang-select"
                                label="Language"
                                value={lang}
                                onChange={handleChange}
                                sx={{ width: 300}}
                                error={!isChange}
                            >
                                <MenuItem value={0}>English, 英語</MenuItem>   
                                <MenuItem value={1}>繁體中文, Tranditional Chinese</MenuItem>
                                <MenuItem value={2}>简体中文, Simplified Chinese</MenuItem>
                                <MenuItem value={3}>日本語, Japanese</MenuItem>
                            </Select>
                            <FormHelperText error sx={{display: isChange ? 'none' : 'flex', my: 2 }}>{t("changeprofilelanguage.option_warning_text")}</FormHelperText>
                            <Button variant="contained" onClick={onChnageProfile} sx={{ mt: isChange? 4 : 0 }} disabled={!isChange}>{t("button.change")}</Button>
                        </FormControl>
                </Box>
                <ErrorMsgDialog open={errorMsgDialogOpen} onClose={handleMsgDialogClose} errorTitle={errorMsgDialogTitle} errorContent={errorMsgDialogContent} needReload={needReload}/>
            </Container>
            </UserLicenseInfoContext.Provider>
        </Box>
    );
    }
};

export default ChangeProfileLanguage