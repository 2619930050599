import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enNs1 from './locales/en/ns1.json';
import tcNs1 from './locales/tc/ns1.json';
import { Cookie } from '@mui/icons-material';

export const defaultNS = 'ns1';
export const fallbackNS = 'fallback';

const options = {
    order: ['querystring', 'sessionStorage', 'cookie'],
    caches: ['cookie'],
    lookupQuerystring: 'lng',
    lookupSessionStorage: 'i18nextLng'
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        supportedLngs: ['en', 'zh'],
        debug: false,
        fallbackLng: 'en',
        detection: options,
        resources: {
            en: { ns1: enNs1, },
            zh: { ns1: tcNs1, },
        },
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false
        },
        defaultNS,
    });

export default i18next;