import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import axios from "axios";
import {Box, Grid, Button, Checkbox, FormControlLabel, FormGroup, Typography, TextField, Link, Alert, Divider } from '@mui/material';
import UserLicenseInfoContext, { EsetKey } from '../../../utils/contexts/UserLicenseInfoContext';
import isDarkMode from '../../../utils/contexts/IsDarkMode';
import ErrorMsgDialog from '../../HandleMsgDialog';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
};

function EmailAlias ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    const userLicenseInfo = useContext(UserLicenseInfoContext);
    const isDark = useContext(isDarkMode)
    const keyArrs = userLicenseInfo?.LICENSE_ESET_SMART_RESULT?.esetKeyList;
    const [showStep1Box, setShowStep1Box] = useState(true);
    const [showStep2Box, setShowStep2Box] = useState(false);
    const [checkAlias, setCheckAlias] = useState(false);
    const [alias, setAlias] = useState('')
    const [password, setPassword] = useState('')
    const [agree, setAgreement] = useState<boolean>(false);
    const [disableCheckbox, setdisableCheckbox] = useState<boolean>(true);
    const [nextCheck, setNextCheck] =  useState<boolean>(true);
    const checkAgreement = () => setAgreement(!agree);
    const [errorMsgDialogOpen, setErrorMsgDialogOpen] = useState(false);
    const [errorMsgDialogTitle, setErrorMsgDialogTitle] = useState(`${t("common.errtitle")}`);
    const [errorMsgDialogContent, setErrorMsgDialogContent] = useState(`${t("common.errdiaglog")}`);
    const [needReload, setNeedReload] = useState<boolean>(false);
    let sync: boolean = false;

    useEffect(() => {
        if (password !== '') {
            setdisableCheckbox(false)
        } else { setdisableCheckbox(true) }
    }, [password]);

    useEffect(() => {
        if ( alias !== '') {
            setNextCheck(false)
        } else { setNextCheck(true) }
    }, [alias]);

    if (userLicenseInfo?.LICENSE_EMAIL_ALIAS_RESULT?.applyTime !== undefined) {
        const appliedTime = Date.parse(userLicenseInfo?.LICENSE_EMAIL_ALIAS_RESULT?.applyTime)
        // console.log("applied time in email alias  "+ appliedTime)
        const currtime = Date.now()
        if (currtime - appliedTime < 14400000) {
            sync = true;
        }
        // console.log(currtime)
    }

    const onCheckAlias = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_CONTEXT_PATH}/api/license/verify/email_alias?alias=${alias}`,
          });
          if (response.status === 200) {
            // console.log("response == 200")
            if (response.data.code === 600) {
                let errMsg: string = '';
                String(response.data.data).split(',').map((item: string) => {
                    errMsg += String(item) + '\n'
                });
                handleMsgDialogOpen(`${t("emailalias.checkerrheader")} (${response.data.pcode})`, errMsg)
                setAlias('');
            } else {
                setCheckAlias(true);
                setShowStep1Box(false);
                setShowStep2Box(true);
            }
          } 
        } catch (error) {
          handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error))
          console.log(error);
        }
    };

    const applyAlias = async () => {
        try {
          const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_CONTEXT_PATH}/api/license/apply/email_alias`,
            data: {"password": password, "alias": alias},
          });
          if (response.status === 200) {
            // console.log("response == 200")
            setNeedReload(true);
            if (response.data.code === 600) {
                handleMsgDialogOpen(`${t("emailalias.errheader")} (${response.data.pcode})`, response.data.data)
                //Wrong password show 600
                setPassword('');
                setAgreement(false);
                setdisableCheckbox(true);
                setNeedReload(false);
            } else {
                handleMsgDialogOpen(`${t("emailalias.warnheader")}`, `${t("emailalias.success")}`)
            }
          } else {
            handleMsgDialogOpen(`${t("emailalias.errheader")}`, `${t("emailalias.errmsg")}`)  
            // throw new Error(`Error: Not able to apply change for student profile language`);
          }
        } catch (error) {
          handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error))
          console.log(error);
        }
    };

    const backToStep1 = () => {
        setShowStep1Box(true);
        setShowStep2Box(false);
        setPassword('');
        setAgreement(false);
        setdisableCheckbox(true);
    }
    
    const handleMsgDialogOpen = (title: string, content: string) => {
        setErrorMsgDialogTitle(title);
        setErrorMsgDialogContent(content);
        setErrorMsgDialogOpen(true);
      };
    
      const handleMsgDialogClose = () => {
        setErrorMsgDialogOpen(false);
    };

    return ( 
        <Box>
            <Typography  sx={{ pt: 5,display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("emailalias.header")}
            </Typography>
            {
                apply_stat === "yes" ?
                <Box sx={{ mt: 4}}>
                    <Typography variant="h6" color={isDark? "#03DAC5":"#38761d"} sx={{ mt:2, mb: 4, display: sync ? 'flex' : 'none'}}>
                        {t("emailalias.sync_stat")}
                    </Typography>
                    <Grid container >
                        <Grid item xs={4} >
                        <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit', fontWeight: 'bold'}}>
                            {t("emailalias.emailaddr")}
                        </Typography>
                        </Grid>
                        <Grid item sx={{ml: {xs: 0, sm:0 , md: 2}, mb: 3}} xs={6}>
                        <Typography variant='h6' color={isDark? "#03DAC5":"#38761d"} sx={{fontWeight: 600}} > {userLicenseInfo?.LICENSE_EMAIL_ALIAS_RESULT?.username}@connect.um.edu.mo </Typography> 
                        </Grid>
                        <Grid item xs={4}>
                        <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit', fontWeight: 'bold'}}>
                            {t("emailalias.emailalias")}
                        </Typography>
                        </Grid>
                        <Grid item sx={{ml: {xs: 0, sm:0 , md: 2}, mb: 3}} xs={6}>
                        <Typography variant='h6' color={isDark? "#03DAC5":"#38761d"} sx={{fontWeight: 600}} > {userLicenseInfo?.LICENSE_EMAIL_ALIAS_RESULT?.alias}@connect.um.edu.mo</Typography> 
                        </Grid>
                    </Grid>
                    <Alert severity="warning" sx={{mt: 1, display: sync ? 'flex' : 'none'}}>                       
                        {/* After registration, please wait approximately <b><em>4 hours</em></b> for the UM@Connect email alias ready. */}
                        <Trans i18nKey="emailalias.warning1" components={{
                            s: <strong />,
                            i: <i />
                        }} >
                        </Trans>
                    </Alert>
                    <Divider sx={{mt:3, mb:2}}></Divider>
                    <Typography variant='subtitle1' >
                        <Trans i18nKey="emailalias.moreinfo">
                            <Link href="https://faq.icto.um.edu.mo/tag/email-alias/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography> 
                </Box>: null
            }

            {   apply_stat === "no" ?
                <Box>
                    <Alert severity="warning" sx={{ mt: 2}}>
                        <Trans i18nKey="emailalias.notapply_warning1" components={{
                            link1: <Link href='http://faq.icto.um.edu.mo/guidelines-for-choosing-umconnect-email-alias/' target='_blank' rel="noreferrer" />,
                            s: <strong />,
                            i: <i />
                        }} >
                        </Trans>
                    </Alert>
                    <Alert severity="warning" sx={{ mt: 2}}>                       
                        <Trans i18nKey="emailalias.notapply_warning2" 
                            components={{
                                s: <strong></strong>, 
                                i: <i></i> }}>
                        </Trans>
                    </Alert>
                    <Alert severity="info" sx={{ mt: 2}} >
                        {t("emailalias.notapply_info1")}
                        <Typography component="ul" variant='subtitle2'>
                            <Trans i18nKey="emailalias.notapply_info2" 
                                components={{
                                    dot1: <li />,
                                    link1: <Link href="http://faq.icto.um.edu.mo/what-is-an-umconnect-email-alias-and-how-to-use-it/" target='_blank' rel="noreferrer" />,
                                    dot2: <li />,
                                    link2: <Link href="http://faq.icto.um.edu.mo/how-do-i-receive-emails-that-sent-to-my-email-alias/" target='_blank' rel="noreferrer" />,
                                    dot3: <li />,
                                    link3: <Link href="http://faq.icto.um.edu.mo/how-do-i-make-my-email-alias-that-appears-as-sender-when-i-using-umconnect-webmail/" target='_blank' rel="noreferrer" />
                                }}
                            ></Trans>
                        </Typography>
                    </Alert>
                    <Grid container spacing={2} sx={{ display: showStep1Box ? 'flex': 'none', justifyContent: 'center', mt: 2}}>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent:"flex-end"}}>
                        <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit'}}>
                            {t("emailalias.prefix")}
                        </Typography>
                        </Grid>
                        <Grid item xs="auto">
                        <TextField size="small" value={alias} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setAlias(event.target.value);}}>
                        </TextField>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'flex-end'}}>
                        <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit'}}>
                            {t("emailalias.subfix")}
                        </Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Button variant="contained" sx={{ mt: 1 }} onClick={onCheckAlias} disabled={nextCheck} >{t("button.submit")}</Button>
                    </Grid>
                    <Grid container spacing={4} sx={{display: showStep2Box ? 'flex': 'none', justifyContent:"center", mt: 2}}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent:"flex-end" }}>
                            <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit'}}>
                                {t("emailalias.prefix")}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} >
                            <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit'}}>
                                {alias}@connect.um.edu.mo
                            </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent:"flex-end"}}>
                            <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit'}}>
                                {t("button.password")}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='h6' sx={{ color: isDark ? '#eeeeee' : 'inherit'}}>
                                <TextField size="small" disabled={agree} label={t("emailalias.pwdhint")} type="password" value={password} sx={{ width: 300}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPassword(event.target.value);}}>
                                </TextField>
                            </Typography>
                        </Grid>
                        <FormGroup sx={{ mt: 2, mb: {xs:2, xl: 0}}}>
                            <FormControlLabel required control={<Checkbox checked={agree} disabled={disableCheckbox} onChange={checkAgreement} color="error"/>} label={t("emailalias.reconfirm")} />
                        </FormGroup>
                        <Grid item xs={12}></Grid>
                        <Button variant="contained" onClick={backToStep1} sx={{ mx: 1}}>{t("button.back")}</Button>
                        <Button variant="contained" disabled={!agree} onClick={applyAlias} sx={{ mx: 1}}>{t("button.submit")}</Button>
                    </Grid>

                </Box>: null
            }
           <ErrorMsgDialog open={errorMsgDialogOpen} onClose={handleMsgDialogClose} errorTitle={errorMsgDialogTitle} errorContent={errorMsgDialogContent} needReload={needReload}/> 
        </Box>
    );
};

export default withTranslation()(EmailAlias);