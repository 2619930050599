import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    Alert,
    Box
  } from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

export interface TwoFaDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function TwofaDialog(props: TwoFaDialogProps) {
    const tips = require('../twofa_tip.jpg')
    const { onClose, open } = props;
    const handleClose = () => {
      onClose();
    };
    const { t } = useTranslation();
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{whiteSpace: 'pre-line'}}>
          <Typography variant='subtitle1' sx={{m: 2}}>
            <Trans i18nKey="twofa.notapplytip1" 
                components={{
                  s: <strong></strong>, 
                }}>
            </Trans>
          </Typography>
          <Box sx={{display:'flex', justifyContent: 'center', px: {xs: 0, sm: 0, md: 8, lg: 8} }}>
            <img src={tips} alt="" style={{width: "90%"}} />
          </Box>
          <Alert severity='warning' sx={{ m: 2 }}>
            <Trans i18nKey="twofa.applied_tip1">
              <Link href='https://icto.um.edu.mo/wp-content/uploads/2022/02/Apply-2FA.pdf' target='_blank' rel="noreferrer" />
              <Link href='https://icto.um.edu.mo/information-security/two-factor-authentication-2fa/' target='_blank' rel="noreferrer" /> 
            </Trans>
          </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{t("button.close")}</Button>
        </DialogActions>
      </Dialog>
    );
}
