import * as React from 'react';
import {Box, Typography, Link, Alert, Paper, Container, Divider} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import isDarkMode from '../../../utils/contexts/IsDarkMode';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

const theme = createTheme();

function UMConnectAlumni ({ apply_stat }: apply_stat) {
    const isDark = React.useContext(isDarkMode);
    const { t } = useTranslation();
    let bgcolor = {};
    if (apply_stat === 'yes') {
        bgcolor = {
            bgcolor: isDark ? '#212121' : '#ededed',
            px: 1.5,
            [theme.breakpoints.down('md')]: {
              bgcolor: isDark? '#313030' : '#f8f6f6'
            }
        }
    } else {
        bgcolor = {bgcolor: 'none'}
    }
    
    return ( 
        <Box sx={{ pt: 5 }}>
            <Box sx={[{py: 1.5 }, bgcolor]}>
                <Typography variant='subtitle1'>
                    {t("connectalum.header1")}
                </Typography>
                <Typography variant='subtitle1' component='ul'>
                    <Trans i18nKey="connectalum.header1_c" components={[<li></li>, <li></li>, <li></li>]}></Trans>
                </Typography>
            </Box>
            { apply_stat === "yes" ? 
            <Box>
            <Typography variant='h5' sx={{mt: 3}}>
            {t("connectalum.body1")}
            </Typography>
            <Typography variant='subtitle1'>
                <Trans i18nKey="connectalum.body1_c">
                <Link href='http://webmail.um.edu.mo' target='_blank' rel="noreferrer" />
                <Link href="https://faq.icto.um.edu.mo/in-what-ways-i-can-access-um-connect/" target='_blank' rel="noreferrer" />
                <Link href="https://faq.icto.um.edu.mo/how-do-i-set-auto-forward-in-umconnect/" target="_blank" rel="noreferrer" />
                </Trans>
            </Typography>
            <Divider sx={{mt:3, mb:2}}></Divider>
                <Typography>
                    <Trans i18nKey="connectalum.moreinfo">
                        <Link href="http://faq.icto.um.edu.mo/category/student-mail/umconnect/" target='_blank' rel="noreferrer" />
                    </Trans>
                </Typography>
            </Box>: null
            }

            {   apply_stat === "no" ? 
                <Box>
                    <Divider sx={{my: 2}}></Divider>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography variant='subtitle1'>
                    <Trans i18nKey="connectalum.agreement">
                        <Link href="https://faq.icto.um.edu.mo/acceptable-use-policy-icto-computing-facilities-campus-network-and-internet/" target="_blank" rel="noreferrer" />
                        <Link href="http://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" target="_blank" rel="noreferrer" />
                        <Link href="http://www.microsoft.com/privacystatement/en-us/OnlineServices/Default.aspx" target="_blank" rel="noreferrer" />
                    </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                     {t("common.agree_note")}
                    </Typography>
                    <Typography variant="subtitle1" component='ul'>
                    <Trans i18nKey="connectalum.note" components={{ dot: <li />}}>
                    </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                    {t("common.notice")}
                    </Typography>
                    <Typography variant="subtitle1" component='ul'>
                    <Trans i18nKey="connectalum.note" components={{ dot: <li />, link1: <Link href="https://www.um.edu.mo/privacy-policy/privacy-policy-statement/" target="_blank" rel="noreferrer" /> }}>
                    </Trans>
                    </Typography>
                    <Alert severity='warning' sx={{ mt: 2}}><Trans i18nKey="connectalum.warning" components={{ s: <strong />, i: <i />}}></Trans></Alert>
                </Box> : null

            }
        </Box>
    );
};
export default withTranslation()(UMConnectAlumni);