import {createTheme} from "@mui/material";

const COLOR = {
    RED: '#f53e41',
    BLUE: '#005f96',
    BLUE_LIGHT: '#00a0fc',
    BLUE_DARK: '#001e30',
    YELLOW: '#ffd446',
    DARK_YELLOW: '#dfab00',
    GREEN: '#00aa94',
    GREY: '#cccccc',
    DARK_BLUE: '#002c55',
};

const DEFAULT_STYLE = {
    SIDE_MENU_WIDTH: 260,
    SHAPE: {
        BORDER_RADIUS: 2
    },
    PRIMARY_COLOR: {
        MAIN: COLOR.BLUE,
        LIGHT: undefined,
        DARK: undefined,
    },
    SECONDARY_COLOR: {
        MAIN: COLOR.DARK_BLUE,
        LIGHT: undefined,
        DARK: undefined,
    },
    ERROR_COLOR: {
        MAIN: '#d32f2f',
        LIGHT: undefined,
        DARK: undefined,
    },
    WARNING_COLOR: {
        MAIN: '#ed6c02',
        LIGHT: undefined,
        DARK: undefined,
    },
    INFO_COLOR: {
        MAIN: '#0288d1',
        LIGHT: undefined,
        DARK: undefined,
    },
    SUCCESS_COLOR: {
        MAIN: '#2e7d32',
        LIGHT: undefined,
        DARK: undefined,
    },
    HEADER_COLOR: COLOR.BLUE,
    HEADER_TEXT_COLOR: '#fff',
    MENU_COLOR: {
        ICON_HIGHTLIGHT: COLOR.BLUE,
        TEXT_HIGHTLIGHT: COLOR.BLUE,
        ICON: 'rgba(136,136,136,0.25)',
        TEXT: '#666',
        INDICATOR: COLOR.YELLOW,
        INDICATOR_SUB_MENU: COLOR.DARK_YELLOW,
        SECTION_TEXT: '#888',
        HIGHTLIGHT_BG: '#dfdfdf',
        HIGHTLIGHT_BG_SUB_MENU: '#d8d8d8',
        BACKGROUND: '#eee',
    },
    BG_COLOR: '#fff',
    TEXT_COLOR: '#333',
    FILTER_BOX: {
        BG_COLOR: 'rgba(136, 136, 136, 0.15)',
        TEXT_COLOR: '#333',
        BORDER: null,
        BOX_SHADOW: null,
    },
    XTABLE: {
        ELEVATION: 0,
        FILTER_BOX_FOCUS_COLOR: '#555',
        COLUMN_DRAGGING_COLOR: COLOR.BLUE_LIGHT,
        TOOL_BAR_MENU: {
            SPACING: '4px',
            PADDING: '0px',
            BG_COLOR: '#e0e0e0',
            BUTTON_BG_COLOR: '#ccc',
            BUTTON_TEXT_COLOR: '#222',
            BUTTON_BORDER_COLOR: '#ccc'
        }
    },
    SNACKBAR: {
        ANCHOR_ORIGIN: {
            VERTICAL: 'bottom', //top, bottom
            HORIZONTAL: 'left', //left, center, right
        }
    },
};

const DARK_MODE_STYLE = {
    ...DEFAULT_STYLE,

    ERROR_COLOR: {
        MAIN: '#a30b2c',
        LIGHT: undefined,
        DARK: undefined,
    },
    HEADER_COLOR: '#111',
    HEADER_TEXT_COLOR: '#fff',
    MENU_COLOR: {
        ICON_HIGHTLIGHT: '#fff',
        TEXT_HIGHTLIGHT: '#fff',
        ICON: 'rgba(136,136,136,0.25)',
        TEXT: '#666',
        INDICATOR: COLOR.YELLOW,
        INDICATOR_SUB_MENU: COLOR.DARK_YELLOW,
        SECTION_TEXT: '#888',
        HIGHTLIGHT_BG: '#1c1c1c',
        HIGHTLIGHT_BG_SUB_MENU: '#282828',
        BACKGROUND: '#111',
    },
    BG_COLOR: '#0c0c0c',
    TEXT_COLOR: '#ddd',
    FILTER_BOX: {
        BG_COLOR: '#1c1c1c',
        TEXT_COLOR: '#ccc',
        BORDER: null,
        BOX_SHADOW: null,
    },
    XTABLE: {
        ELEVATION: 0,
        FILTER_BOX_FOCUS_COLOR: '#ddd',
        COLUMN_DRAGGING_COLOR: COLOR.DARK_YELLOW,
        TOOL_BAR_MENU: {
            SPACING: '4px',
            PADDING: '0px',
            BG_COLOR: '#333',
            BUTTON_BG_COLOR: '#454545',
            BUTTON_TEXT_COLOR: '#ddd',
            BUTTON_BORDER_COLOR: '#454545'
        }
    }
};

let STYLE = DEFAULT_STYLE;

const theme = createTheme({
    palette: {
        primary: {main: STYLE.PRIMARY_COLOR.MAIN},
        secondary: {main: STYLE.SECONDARY_COLOR.MAIN},
        error: {main: STYLE.ERROR_COLOR.MAIN},
        warning: {main: STYLE.WARNING_COLOR.MAIN},
        info: {main: STYLE.INFO_COLOR.MAIN},
        success: {main: STYLE.SUCCESS_COLOR.MAIN},
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

export default theme