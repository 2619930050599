import React, { useEffect, useState, useContext } from 'react';
import axios, { AxiosError } from 'axios';
import UserInfoContext from '../utils/contexts/UserInfoContext';
import UserLicenseInfoContext,{ UserLicenseInfo } from '../utils/contexts/UserLicenseInfoContext';
import isDarkMode from '../utils/contexts/IsDarkMode';
import { Box, Container, Grid, Typography, CircularProgress} from '@mui/material';
import CustomizedToggle from "../components/license/CustomedToggle";
import { LicenseDatas } from "../components/license/LicenseData"
import LicenseCard from "../components/license/LicenseCard";
import { CustomToggleBanner, MyLicensePage } from '../utils/MyLicenseStyle';
import { useTranslation } from 'react-i18next';
import { isUMApp, PageModule } from '@um/js-api';

interface UMAppContextType {
  isApp: boolean;
}

const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};

const MyLicense = () => {
  const { t } = useTranslation();
  if (isUMApp()) {
    PageModule.setTitle(`${t("applylicense.pagetitle")}`)
  }
  sessionStorage.removeItem("prevPath");
  document.title="My License"
  const [userLicenseInfo, setUserLicenseInfo] = useState<UserLicenseInfo | null>(null);
  // const loginContext = React.useContext(LoginContext);
  // console.log("Start mylicense")
  const userInfo = useContext(UserInfoContext);
  const isDark = useContext(isDarkMode)
  const [isapply, setapply] = useState("yes");
  const [applied, setAppliedArr] = useState<string[]>([]);
  const [notApplied, setNotAppliedArr] = useState<string[]>([]);
  // const [appliedNum, setAppliedNum] = useState(0);
  // const [notAppliedNum, setNotAppliedNum] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  let comparearr: string[] = [];
  
  const needToDisplayLicenses = ['LICENSE_ENDNOTE', 'LICENSE_ESET_SMART','LICENSE_LIBRARY', 'LICENSE_OPEN_DATA', 'LICENSE_PRO_PLUS', 'LICENSE_QUALTRICS','LICENSE_UMCONNECT_STUDENT', 'LICENSE_UMCONNECT_ALUMNI', 'LICENSE_EMAIL_ALIAS', 'LICENSE_WORK_AT_HOME', 'LICENSE_ZOOM', 'ACCOUNT_LOTUS_NOTES', 'LICENSE_RESEARCH_ETHICS'];

  useEffect(() => {
      let respData : UserLicenseInfo;
      if (userLicenseInfo === null && location.pathname !== `${process.env.REACT_APP_CONTEXT_PATH}/error`) {
        axios
          .get(`${process.env.REACT_APP_CONTEXT_PATH}/api/account/get_account_info`)
          .then((resp) => {
            // console.log("========= Start Getting data from API ========")
            respData=resp.data.data;
            respData['Service'] = (resp.data.role.services);
            // console.log(respData);
            // console.log("========= End Getting data from API ========")
            setUserLicenseInfo(respData as UserLicenseInfo);
          })
          .catch((error: AxiosError) => {
            console.log(error);
          });
      }
  }, [userLicenseInfo]);
  // console.log(userLicenseInfo)


  useEffect(() => {
    if ((userLicenseInfo) !== null && (userLicenseInfo) !== undefined) {
      setIsLoading(false)
      if(!userLicenseInfo['Service']){
        // throw new Error("Error: User License Info Service undefined");
        // ErrMsg = "Error: No User License Info is returned";
      } else {
        // console.log(userLicenseInfo)
        userLicenseInfo['Service'].map( (value) =>{ 
          // console.log(value + " " + userLicenseInfo[value]);
          if ( needToDisplayLicenses.includes(value) ) {
            if (userLicenseInfo[value as keyof UserLicenseInfo]) {
              if (!applied.includes(value)) {
                setAppliedArr(applied => [...applied, value])
              }
            } else {
              if (!notApplied.includes(value)) { 
                if (value !== 'LICENSE_EMAIL_ALIAS' && value != 'ACCOUNT_LOTUS_NOTES' ) {
                  setNotAppliedArr(notApplied => [...notApplied,value]);
                } else if (userLicenseInfo['LICENSE_UMCONNECT_STUDENT'] || userLicenseInfo['LICENSE_UMCONNECT_ALUMNI'] && value == 'LICENSE_EMAIL_ALIAS') {
                  setNotAppliedArr(notApplied => [...notApplied,value]);
                }
              }
            }
          }
        })
      }    
    }
  }, [userLicenseInfo]);

  // useEffect(() => {
  //   setAppliedNum(applied.length);
  //   setNotAppliedNum(notApplied.length);
  // }, [applied]);

  if (isapply === 'yes') { 
      comparearr = applied
  }
  else {
      comparearr = notApplied
  };
  
  // console.log(comparearr)
  // console.log(isError
  // console.log("In my mylicense")
  // console.log("Context's isDarkMode" + isDark)
  // console.log("end mylicense")

  return (
    <Box sx={MyLicensePage(isDark, isUMApp())}>
      <UserLicenseInfoContext.Provider value={userLicenseInfo}>
        <Container maxWidth="xl" sx={[ {borderTopLeftRadius: 20,borderTopRightRadius: 20} , CustomToggleBanner(isDark)]} >
          <Grid container>
            <Grid item xs={12} md={12} sx={{ mt : 2, display: 'flex', justifyContent: 'center' }} >
                <CustomizedToggle isapply={isapply} setapply={setapply} />
            </Grid>
          </Grid>
        </Container>
        {isLoading && (
                <CircularProgress
                      size={35}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
        )}
        <Container maxWidth="xl" sx={[{ pb: {xs: 18, sm: 18, md: 3, lg: 3}, mb: {xs: 0, sm: 0, md:6, lg: 6, xl: 6}, minHeight: "85vh", display: isLoading ? 'none': 'inherit' }, CustomToggleBanner(isDark)]}>
          <Grid container spacing={{xs: 3, sm: 2, md: 9}} sx={{ pl:2, pr: 4, display: "flex", pt: {sx: 4, sm: 4, md: 6}}}>            
            { comparearr.length > 0 ?            
              LicenseDatas.map((license, index) => (
              comparearr.some((value) => value === license.name) ? 
                (<LicenseCard id={license.id}
                            key={index}
                            name={license.name} 
                            title={license.title}
                            desc={license.desc}
                            bgcolor={license.bgcolor}
                            icon={license.icon}
                            apply_stat={isapply}
                            content={license.content}
                            apply_agreement={license.apply_agreement}
                            setapply={setapply}
                            setUserLicenseInfo={setUserLicenseInfo}
                />): null
            )) : isapply === "yes" ? 
                <Grid item xs={12} md={12} sx={{ mt : 2, display: 'flex', justifyContent: 'center' }} >
                  <Typography variant='h6' color="text.primary">{t("common.noLicenseReg")}</Typography>
                </Grid>: 
                <Grid item xs={12} md={12} sx={{ mt : 2, display: 'flex', justifyContent: 'center' }} >
                  <Typography variant='h6' color="text.primary">{t("common.noLicenseApply")}</Typography>
                </Grid>
            }
          </Grid>    
        </Container>
      </UserLicenseInfoContext.Provider>
    </Box>
    
  );
};

export default MyLicense;
