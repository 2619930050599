import { createTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import apptheme from './Config'

const theme = createTheme();

export const CustomToggleBanner = (isDark: boolean) => {
    return ({
        bgcolor: 'interit',
        [theme.breakpoints.down('md')]: {
             bgcolor: isDark ? '#515151' :'white',
        },
    })   
}

export const MyLicensePage = (isDark: boolean, isApp: boolean) => {
  const pageBgcolorMobile = isDark ? '#1c1b20': '#012C56'
  return ({
      bgcolor: 'transparent',
      borderTop: 0,
      boxShadowTop: 'none',
      border: isDark? 1 : 0,
      borderColor: isDark? '#515151' : 'inherit',
      [theme.breakpoints.down('md')]: {
        bgcolor: isApp ? pageBgcolorMobile : 'transparent',
        borderTop: isApp ? 10 : 0,
        borderColor: isApp ? pageBgcolorMobile : isDark? '#515151' : 'inherit',
       //  boxShadowTop: isDark ? 0 : 3,
      },
  })   
}

interface customToggleProps {
    isdark: string,
    selectedcolor: String,
    selectedfontcolor: String
  }
  
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)<customToggleProps>(({ theme, isdark, selectedcolor, selectedfontcolor, value}) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      height: 40,
      color: '#eae6e6' ,
      fontWeight: 500,
      backgroundColor: isdark === "yes" ? '#312c2c' : 'interit',
      [theme.breakpoints.down('md')]: {
        color: 'grey',
        backgroundColor: isdark === "yes" ? '#515151' : 'interit',
        margin: theme.spacing(0.5),
        fontWeight: 550,
      },
    },
    '& .MuiToggleButton-root.Mui-selected':{
      display: 'flex',
      color: 'white',
      backgroundColor: selectedcolor,
      [theme.breakpoints.down('md')]: {
        backgroundColor: isdark === "yes" ? '#515151': 'white',
        color: isdark === "yes" ? selectedfontcolor : apptheme.palette.primary.main,
        fontWeight: 750,
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        textUnderlineOffset: '0.4em',
      },
    },
    '& .MuiToggleButton-root.Mui-selected:hover' :{
      color: 'white',
      backgroundColor: selectedcolor,
      [theme.breakpoints.down('md')]: {
        backgroundColor: isdark === "yes" ? '#515151': 'white',
        color: isdark === "yes" ? selectedfontcolor : apptheme.palette.primary.main,
        fontWeight: 800,
      },
    },
    '& .MuiToggleButtonGroup-grouped:not(:last-of-type), & .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      borderRadius: 4,
    },
    '& .MuiButtonBase-root': {
      px: 10,
    },
  }));