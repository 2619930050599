import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from '@mui/material';
import React, { useState } from 'react';
import { isUMApp, AppModule } from '@um/js-api';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

export interface ErrorMsgDialogProps {
    open: boolean;
    needReload: boolean;
    homeReload?: boolean;
    errorTitle: string;
    errorContent: string;
    onClose: () => void;
}
interface UMAppContextType {
  isApp: boolean;
}
const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};


export default function ErrorMsgDialog(props: ErrorMsgDialogProps) {
    const { onClose, open, errorTitle, errorContent, needReload, homeReload } = props;
    const handleClose = () => {
      onClose();
      if (needReload) {
        window.location.reload()
      }
      if (homeReload !== null) {
        if (homeReload) {
          if (isUMApp()) {
            AppModule.closePage()
          } else {
            window.location.replace("https://umpass.um.edu.mo");
          }
        }
      }
    };
    const { t } = useTranslation();
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {errorTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{whiteSpace: 'pre-line'}}>
            {errorContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{t("button.close")}</Button>
        </DialogActions>
      </Dialog>
    );
  }
