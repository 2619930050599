import * as React from 'react';
import {Divider, Box, Typography, Link, Grid} from '@mui/material'
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function WorkAtHomeContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5, display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("workathome.header")}
            </Typography>
            { apply_stat === "yes" ? 
                <Box>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                    {t("workathome.licenseinfo")}
                    </Typography>
                    <Typography variant='subtitle1' component="ul">
                        <Trans i18nKey="workathome.expire" components={[<li></li>]}></Trans>
                    </Typography>
                    <Typography variant='subtitle1' sx={{ mt:3}} >
                        {t("workathome.body1")}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} sx={{border:1 }}>{t("workathome.win10edu")}</Grid>
                        <Grid item xs={6} sx={{borderLeft:1, borderBottom: 1 }}>{t("workathome.engver")}</Grid>
                        <Grid item xs={3} sx={{borderLeft: 1, borderBottom: 1 }}><Link href="https://umdrive.um.edu.mo/link/AA682BBEAD901B4407AC88F6E648678AF7" target="_blank" rel="noreferrer">32-bit</Link></Grid>
                        <Grid item xs={3} sx={{borderLeft:1, borderBottom: 1, borderRight:1}}><Link href="https://umdrive.um.edu.mo/link/AA4866AE90578448C8A570C6E8F11626A1" target="_blank" rel="noreferrer">64-bit</Link></Grid>
                        <Grid item xs={6} sx={{borderLeft:1, borderBottom: 1 }}>{t("workathome.tradver")}</Grid>
                        <Grid item xs={3} sx={{borderLeft:1, borderBottom: 1}}><Link href="https://umdrive.um.edu.mo/link/AA69031F1BB6974387B56016BF51122A35" target="_blank" rel="noreferrer">32-bit</Link></Grid>
                        <Grid item xs={3} sx={{borderLeft:1, borderBottom: 1, borderRight:1 }}><Link href="https://umdrive.um.edu.mo/link/AAC60CC2CF9314473AA1ED242038C96B11" target="_blank" rel="noreferrer">64-bit</Link></Grid>
                        <Grid item xs={6} sx={{borderLeft:1, borderBottom: 1 }}>{t("workathome.simver")}</Grid>
                        <Grid item xs={3} sx={{borderLeft:1, borderBottom: 1 }}><Link href="https://umdrive.um.edu.mo/link/AAF7F7A3FF942C42A883293FA3F0B6A021" target="_blank" rel="noreferrer">32-bit</Link></Grid>
                        <Grid item xs={3} sx={{borderLeft:1, borderBottom: 1, borderRight:1 }}><Link href="https://umdrive.um.edu.mo/link/AAA5E3DFD0379C484B809E905A9293581B" target="_blank" rel="noreferrer">64-bit</Link></Grid>
                    </Grid>
                    <Typography variant='subtitle1' sx={{mt: 3}}>
                        <Trans i18nKey="workathome.body2">
                            <Link href="http://www.ashampoo.com/en/usd/pin/0710/Offline/Ashampoo-Burning-Studio-6" target="_blank" rel="noreferrer" />
                            <Link href="https://icto.um.edu.mo/help-desk-services/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Typography variant='subtitle1' sx={{mt:2}}>
                        <Trans i18nKey="workathome.body3" components={{
                            i: <i />,
                            link1: <Link href="http://www.ashampoo.com/en/usd/pin/0710/Offline/Ashampoo-Burning-Studio-6" target="_blank" rel="noreferrer" />
                        }}></Trans>
                    </Typography>
                    <Typography variant='subtitle1' sx={{fontWeight: 600, mt: 3}}>{t("workathome.activateguide")}</Typography>
                    <Typography variant='subtitle1'>
                        <Trans i18nKey="workathome.refer">
                            <Link href="https://faq.icto.um.edu.mo/how-to-activate-windows-7-and-8-work-at-home/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Divider sx={{mt: 3, mb:2}}></Divider>
                    <Typography>
                        <Trans i18nKey="workathome.moreinfo">
                            <Link href="https://faq.icto.um.edu.mo/tag/work-at-home/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                    
                </Box>: null
            }

            {   apply_stat === "no" ?
                <Box>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                       {t("workathome.form")}
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 400, mt: 2}}>
                        <Trans i18nKey="workathome.form_c" components={{ s: <strong />, n: <br />}}> </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                    {t("workathome.software")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 400, mt: 1}}>
                    {t("workathome.software_c")}
                        <Typography variant='subtitle1' component="ul" sx={{ fontWeight: 400, mt: 2}}>
                            <li>{t("workathome.software_c1")}</li>
                            <Typography variant="subtitle1" color="#FF0000" sx={{ fontWeight: 400, mt: 1, mb:1}}>
                            {t("workathome.software_c2")}
                            </Typography>
                        </Typography>
                        <Typography variant='subtitle1' color="red" sx={{ fontWeight: 400}} >
                        {t("workathome.software_c3")}
                        </Typography>
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                    {t("common.statement")}
                    </Typography>
                    <Typography variant='subtitle1' component='ul' sx={{ fontWeight: 400 }} >
                        <Trans i18nKey="workathome.statement" components={{ dot: <li />, link1: <Link href="https://support.microsoft.com/en-us/help/4028142/windows-10-system-requirements" target="_blank" rel="noreferrer" />}}> 
                        </Trans>    
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(WorkAtHomeContent);