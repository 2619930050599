import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link'
import { Divider } from '@mui/material';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function ResearchEthicsContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5, fontWeight: 400}} variant='subtitle1'>
                {t("researchEthics.header")}
            </Typography>
            { apply_stat === "yes" ?
                <Box>
                    <Typography variant='subtitle1' sx={{mt: 1}}>
                        <Trans i18nKey="researchEthics.goto">
                        <Link href='https://forms.researchethics.um.edu.mo' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Divider sx={{ mt: 3, mb: 2}}></Divider>
                    <Typography>
                        <Trans i18nKey="researchEthics.moreinfo">
                            <Link href="https://rskto.um.edu.mo/" target="_blank" rel="noreferrer" /></Trans>
                    </Typography>
                </Box> : null
            }
            {   apply_stat === "no" ?
                <Box>
                    <Divider sx={{ mt: 3}}></Divider>
                    <Typography variant='subtitle1' sx={{ mt: 2}}>
                        {t("researchEthics.notapply_header")}
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.remark")}
                        <Typography variant='subtitle1' component='ul' sx= {{fontWeight: 400}}>
                            <Trans i18nKey="researchEthics.remark" components={{
                                dot1: <li />,
                                dot2: <li />,
                                dot3: <li />,
                                dot4: <li />,
                                s: <strong />,
                                link1: <Link href='https://icto.um.edu.mo/help-desk-services/' target='_blank' rel="noreferrer" />
                            }}></Trans>
                        </Typography>
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2, mb: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography>
                        <Trans i18nKey="researchEthics.agreement">
                            <Link href='https://www.infonetica.net/ethics-rm-privacy-policy/' target='_blank' rel="noreferrer" />
                            <Link href='https://rskto.um.edu.mo/um-research-ethics-review-system/' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ mt: 3}}>
                        <Trans i18nKey="researchEthics.notice" components={[<b></b>]}></Trans>
                        <Typography variant='subtitle1' component='ul' sx= {{fontWeight: 400}}>
                            <Trans i18nKey="researchEthics.notice_c" components={{
                                dot1: <li />,
                                dot2: <li />,
                                dot3: <li />,
                                link1: <Link href='https://www.um.edu.mo/privacy-policy/privacy-policy-statement/' target='_blank' rel="noreferrer" />
                            }}></Trans>
                        </Typography>
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(ResearchEthicsContent);