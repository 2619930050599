import * as React from 'react';
import { useContext, useState } from 'react';
import {Box, Typography, Link, TextField, Divider, Alert, AlertTitle} from '@mui/material';
import UserLicenseInfoContext, { EsetKey } from '../../../utils/contexts/UserLicenseInfoContext';
import CopyToClipboardButton from '../../CopyToClipboardButton';
import isDarkMode from '../../../utils/contexts/IsDarkMode';
import dayjs from "dayjs";
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
};

function EsetContent ({ apply_stat }: apply_stat) {
    const userLicenseInfo = useContext(UserLicenseInfoContext);
    const isDark = useContext(isDarkMode)
    const keyArrs = userLicenseInfo?.LICENSE_ESET_SMART_RESULT?.esetKeyList
    const { t } = useTranslation();

    return ( 
        <Box>
            {/* <Alert severity="error" sx={{ my: 5, fontSize: 18 }} variant="filled" style={{whiteSpace: 'pre-line'}}>
                <AlertTitle sx={{fontSize: 20 }}>{t("eset.attention")}</AlertTitle>
                    <Trans i18nKey="eset.maintenance" components={{s: <strong></strong>}}></Trans>
                </Alert> */}
            {/* pt: 5 */}
            <Typography  sx={{ pt: 5,display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("eset.header")}
            </Typography>
            {
                apply_stat === "yes" ?
                <Box>
                    <Typography variant='h6' color={ isDark ? "#03DAC5":"#005f96"} sx={{ fontWeight: 600, mt: 2}}>
                        {t("eset.licenseinfo")}
                    </Typography>
                    <Typography variant='subtitle1'>
                    {t("eset.licenseinfo_c")}
                    </Typography>
                    {
                        keyArrs !== null && keyArrs !== undefined && keyArrs?.length !== 0 ?
                        <Box >
                            {keyArrs.map(( keyArr: EsetKey, index: number ) => {
                                const ftApplyTime = dayjs(keyArr.applyTime).format('YYYY-MM-DD HH:mm:ss');
                                return(
                                <Box sx={{ mt: 3}}>
                                    <Typography variant='subtitle2' color={ isDark ? "#03DAC5":"#005f96"} sx={{ fontWeight: 400}}>
                                        {t("eset.applied_date", {index: index+1, ftApplyTime: ftApplyTime})}
                                    </Typography>
                                    <TextField 
                                        id={`keytxt`+{index}} 
                                        // label={keyArr.key}
                                        defaultValue={keyArr.key}  
                                        sx={{ mr: {xs: 2, sm: 2, md: 4, lg: 4}, width: {xs:205, sm: 205} }} 
                                        InputProps={{ readOnly: true, }}
                                        size="small"
                                     />
                                    <CopyToClipboardButton index={index} type="eset" />
                                </Box>
                            )}
                            )}
                        </Box>: 
                        <Typography variant='subtitle1'>
                            {t("eset.error")}
                        </Typography>
                    }
                    <Typography variant='h6' sx={{ fontWeight: 600, mt: 4}}>
                        {t("eset.installguide")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{ }}>
                        <Trans i18nKey="eset.refer">
                            <Link href="http://faq.icto.um.edu.mo/what-is-the-eset-smart-security-home-user-license-application-and-renewal-procedure/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Typography variant='h6' sx={{ fontWeight: 600, mt: 2}}>
                        {t("eset.extendguide")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{ }}>
                        <Trans i18nKey="eset.refer">
                            <Link href="http://faq.icto.um.edu.mo/how-to-extend-the-eset-license-for-current-home-user/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Divider sx={{mt:3, mb:2}}></Divider>
                    <Typography variant='subtitle1'>
                        <Trans i18nKey="eset.moreinfo">
                            <Link href="https://faq.icto.um.edu.mo/tag/eset/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                </Box>: null
            }

            {   apply_stat === "no" ?
                <Box>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.notice")}
                    </Typography>
                    <Typography variant='subtitle1' component='ul' sx={{  mt: 1}} >
                        <Trans i18nKey="eset.notice" components={[<li></li>, <li></li>]}></Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.disclaimer")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 400, mt: 1}}>
                        {t("eset.disclaimer")}
                    </Typography>
                </Box>: null
            }
            
        </Box>
    );
};
export default withTranslation()(EsetContent);