import * as React from 'react';
import {Divider, Box, Typography, Link, Grid} from '@mui/material'
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
};

function EndNoteContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5,display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("endnote.header1")}
            </Typography>
            <Typography variant='subtitle1' sx={{ fontWeight: 400, mt: 2}}>
                <Trans i18nKey="endnote.header2">
                    <Link href="https://um-mo.libguides.com/c.php?g=948500" target="_blank" rel="noreferrer" />
                </Trans>
            </Typography>
            { apply_stat === 'yes' ?
                <Box>
                <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                    {t("endnote.download")}    
                </Typography>
                <Typography variant='subtitle1' component='ul' sx={{ fontWeight: 400 }} >
                    <li><Grid container>
                            <Grid item xs={6} md={4} lg={3}>
                                <Link href="https://umdrive.um.edu.mo/link/AA50A981AA0D924C8EA2883FDBD57B6D7D" target="_blank" rel="noreferrer">
                                {t("endnote.endnote21wins")}
                                </Link>
                            </Grid>
                            <Grid item xs={6} md={6}>{t("endnote.password")} HQBk</Grid>
                        </Grid>
                    </li> 
                    <li><Grid container>
                            <Grid item xs={6} md={4} lg={3}>
                                <Link href="https://umdrive.um.edu.mo/link/AA30E7932598DD47C38530D4526BDA1E2E" target="_blank" rel="noreferrer">
                                {t("endnote.endnote21mac")}</Link>
                            </Grid>
                            <Grid item xs={6} md={6}>{t("endnote.password")} 5CFd</Grid>
                        </Grid>
                    </li> 
                </Typography>
                {/* <Divider sx={{ mt: 3, mb: 2}}></Divider> */}
                {/* <Typography>
                    <Trans i18nKey="endnote.moreinfo">
                        <Link href="https://um-mo.libguides.com/c.php?g=948500&p=6874231" target="_blank" rel="noreferrer" />
                    </Trans>
                </Typography> */}
                </Box> : null
            }
            { apply_stat === "no" ?
                <Box>
                    <Divider sx={{my: 2}}></Divider>
                    <Typography variant='subtitle1' sx={{ fontWeight: 400, mt: 2}}>
                        {t("endnote.header_notapply")}
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.statement")}
                    </Typography>
                    <Typography variant='subtitle1' component='ol' sx={{ fontWeight: 400 }} >
                        <Trans i18nKey="endnote.notice" components={{ dot: <li />}}></Trans>
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(EndNoteContent)