import { useContext, useEffect, useState } from 'react';
import UserInfoContext from '../utils/contexts/UserInfoContext';
import {useLocation, useNavigate} from 'react-router-dom'

export default function Root() {
  const userInfo = useContext(UserInfoContext);
  const location = useLocation();
  const navigate = useNavigate();
  // console.log ("+++++In root+++++=++=")

  useEffect(() => {
    const needRedirect = sessionStorage.getItem("prevPath")
    console.log(needRedirect)
    if (needRedirect !== null) {
      navigate(needRedirect);
    } else window.location.replace("https://umpass.um.edu.mo");  
  }, []);
  return (
    <div className='App'>   
      {/* <form action={`${process.env.REACT_APP_CONTEXT_PATH}/logout`} method='post'>
        <input type='submit' value='logout' />
        <h1>Username: {`${userInfo?.username}`}</h1>
        <h1>CSRF Token: {`${userInfo?.csrfToken}`}</h1>
      </form> */}
    </div>
  );
}
