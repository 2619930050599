import {
  AppBar,
  Box,
  Grid,
  styled,
  Toolbar,
  Typography,
  Button,
  Container,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DevicesIcon from '@mui/icons-material/Devices';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';

import React from 'react';
import { useContext, useState, useEffect } from 'react';
import UserInfoContext from '../../utils/contexts/UserInfoContext';
import UserLicenseInfoContext from '../../utils/contexts/UserLicenseInfoContext';
import { useNavigate } from 'react-router-dom';
import { UMAppContext } from '../../UMApp';
import { isUMApp } from '@um/js-api'
import Cookies from 'js-cookie';
import i18next from 'i18next';
import { useTranslation  } from 'react-i18next';


const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const checkProfileRight = () => {
  const licenseInfo = useContext(UserLicenseInfoContext);
  if (licenseInfo !== null && licenseInfo.Service.includes('ACCOUNT_CHANGE_PROFILE_LANGUAGE')) {
    // console.log("check right return true")
    return true;
  } else {
    return false;
  }
};

const checkTwofaRight = () => {
  const licenseInfo = useContext(UserLicenseInfoContext);
  if (licenseInfo !== null && licenseInfo.Service.includes('ACCOUNT_TWOFA')) {
    // console.log("check 2fa right return true")
    return true;
  } else {
    return false;
  }
};

export default function FacAppBar() {
  const umappContext = useContext(UMAppContext);
  const userInfo = useContext(UserInfoContext);
  const userLicenseInfo = useContext(UserLicenseInfoContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorLang, setAnchorLang] = React.useState<null | HTMLElement>(null);
  const [funcanchorEl, setFuncAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parameterName = '_csrf';
  const token = Cookies.get('XSRF-TOKEN');
  
  const handleLoginMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLang(event.currentTarget);
  };

  const handleFuncMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFuncAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorLang(null);
    setFuncAnchorEl(null);
  };

  const linkto = (value: string) => {
    navigate(`/${value}`);
    handleClose();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed' sx={{ boxShadow: { xs: 0, sm: 0, md: 'inherit', lg: 'inherit' } }}>
        <Container maxWidth='xl'>
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={handleFuncMenu}
              aria-controls={`${Boolean(funcanchorEl)}` ? 'function-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={`${Boolean(funcanchorEl)}` ? 'true' : undefined}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='function-menu'
              anchorEl={funcanchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(funcanchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                  linkto('changepassword');
                }}
              >
                <AccountCircleIcon sx={{ mr: 1.5 }} />
                {t("appbar.changepassword")}
              </MenuItem>
              { checkProfileRight() && userLicenseInfo !== null && userLicenseInfo !== undefined ?
              <MenuItem onClick={() => {
                  linkto('changeprofilelanguage');
                }}
              >
                <SettingsIcon sx={{ mr: 1.5 }} />
                {t("appbar.changeprofilelang")}
              </MenuItem> : null
              }
              { checkTwofaRight() && userLicenseInfo !== null && userLicenseInfo !== undefined ?
                <MenuItem onClick={() => {
                    // linkto('twofa');
                    window.location.replace(`${process.env.REACT_APP_CONTEXT_PATH}/twofa`)
                  }}>
                  <VpnKeyIcon sx={{ mr: 1.5 }} />
                  {t("appbar.twofa")}
                </MenuItem> : null
              }
              <Divider />
              <MenuItem
                onClick={() => {
                  linkto('mylicense');
                }}
              >
                <DevicesIcon sx={{ mr: 1.5 }} />
                {t("appbar.mylicense")}
              </MenuItem>
            </Menu>

            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}></Typography>
            <Button
              color='inherit'
              onClick={handleLanguageMenu}
              aria-controls={`${Boolean(anchorLang)}` ? 'language-menu-appbar' : undefined}
              aria-haspopup='true'
              aria-expanded={`${Boolean(anchorLang)}` ? 'true' : undefined}
            >
              <LanguageIcon />
            </Button>
            <Menu
              id='language-menu-appbar'
              anchorEl={anchorLang}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorLang)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                  i18next.changeLanguage('en')
              }}>
                English
              </MenuItem>
              <MenuItem onClick={() => {
                  i18next.changeLanguage('zh')
              }}>
                繁體中文
              </MenuItem>
            </Menu>

            <Button
              color='inherit'
              onClick={handleLoginMenu}
              aria-controls={`${Boolean(anchorEl)}` ? 'login-menu-appbar' : undefined}
              aria-haspopup='true'
              aria-expanded={`${Boolean(anchorEl)}` ? 'true' : undefined}
            >
              {userInfo?.username}
            </Button>
            <Menu
              id='login-menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <form
                  action={`${process.env.REACT_APP_CONTEXT_PATH}/logout?${parameterName}=${token}`}
                  method='post'
                >
                  <input type={`hidden`} name={parameterName} value={token} />
                  <IconButton
                    size='small'
                    aria-label='Logout'
                    edge='end'
                    color='inherit'
                    type='submit'
                  >
                    <LogoutIcon sx={{ mr: 1.5 }}/>
                    {t("button.logout")}
                  </IconButton>
                </form>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <Offset />
    </Box>
  );
}
