import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
  const { t } = useTranslation();
  return (
    <div>
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', mt: 5}}>
      <Typography variant='h6'>
        {t("common.pagenotfound")}
      </Typography>
      </Container>   
    </div>
  );
}
