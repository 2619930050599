import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { UMAppContext } from './UMApp';

const UNAUTHORIZED = 401;

function AuthSupport(props: { children: ReactElement | null }) {
  const [ready, setReady] = useState<boolean>(false);
  const umappContext = useContext(UMAppContext);
  const location = useLocation();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
          if (!umappContext.isApp && location.pathname !== `/home` && location.pathname !== `/`) {
            sessionStorage.setItem('prevPath', location.pathname);
            // eslint-disable-next-line no-restricted-globals
            // @ts-ignore
            window.location.assign(process.env.REACT_APP_LOGIN_URL);
          } else {
            console.log('Invalid access token');
          }
        }
        return Promise.reject(error);
      },
    );
    setReady(true);
  }, []);

  return ready ? props.children : <></>;
}

export default AuthSupport;
