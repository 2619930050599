import {createContext} from "react";

export interface AccountStatus {
    username: string,
    passwordLastSet: Date,
    accountExpiryDate: Date | null,
    activationTime: Date | null,
    twoFA: boolean,
    active: boolean
}

const AccountStatusContext = createContext<AccountStatus | null>(null);
export default AccountStatusContext;