import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container, Paper } from '@mui/material';
import { useContext } from 'react';
import { createTheme } from '@mui/material/styles';
import isDarkMode from '../../utils/contexts/IsDarkMode';
import Link from '@mui/material/Link';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

const theme = createTheme();

export default function TwoFAAgreement() {
  const isDark = useContext(isDarkMode)
  const { t } = useTranslation();
  const bgcolor = {
    bgcolor: isDark ? '#212121' : '#fff',
    [theme.breakpoints.down('md')]: {
      bgcolor: isDark? '#111111' : '#fff'
    }
  }
  
  return (
    <Container disableGutters sx={{ width: '98%', alignContent: 'center', px: 2 }}>
      <Typography variant='h5' color="text.primary" sx={{mt: 1}}>
        {t("twofa.header")}
      </Typography>
      <Paper sx={[{ maxHeight: 400, overflow: 'auto', my: 2, px: 1, pt: 1, pb: 2 }, bgcolor]}>
        <Typography variant='subtitle1' sx={{ fontWeight: 600}}>I agree and accept that:</Typography>
        <Typography variant='body2' component="ul" sx={{fontSize: 15}}>
            <li>
              This service is provided by Duo Security which does not have data center in Macao.
              Therefore, to create the account of Two-factor authentication service, your UMPASS
              account ID, UM email address "[yourUserID]@um.edu.mo", user IP address or mobile phone
              number (if registered during enrollment) will be passed and stored to Duo Security
              outside Macao. Duo Security’s will implicitly know your status of being a member of
              the University of Macau.
            </li>
            <li>
              Your right to use DUO Security Two-factor authentication service will be expired after
              you resign or leave from the University.
            </li>
            <li>
              In using this system, the user agrees to the above and acknowledges and accepts the
              terms and conditions contained in the Regulation of University of Macau on the Privacy
              Policy Statement, available at:{' '}
              <Link href='https://www.um.edu.mo/privacy-policy/privacy-policy-statement/' target='_blank' rel="noreferrer">
                https://www.um.edu.mo/privacy-policy/privacy-policy-statement/
              </Link>
            </li>
            <li>
              <Link href='https://faq.icto.um.edu.mo/acceptable-use-policy-icto-computing-facilities-campus-network-and-internet/' target='_blank' rel="noreferrer">
                Acceptable Use Policy - ICTO Computing Facilities, Campus Network and Internet  
              </Link>
          ,{' '}
          <Link href='https://duo.com/legal/terms' target='_blank' rel="noreferrer">
            Duo Security Service Terms
          </Link>
          {' '}
          and{' '}
          <Link href='https://duo.com/legal/privacy-notice-general' target='_blank' rel="noreferrer">
            Duo Security Conditions and General Privacy Notice
          </Link>
          .
            </li>
        </Typography>
        <Typography variant='subtitle1' sx={{ fontWeight: 600}}>本人同意並接受:</Typography>
        <Typography variant='body2' component="ul" sx={{fontSize: 15}}>
            <li>
              這項服務是由Duo
              Security所提供，由於它在澳門並沒有數據中心，因此，為了建立雙重認證服務帳戶之需要，以下資料包括您的UMPASS
              帳戶名稱、澳大電郵地址"[yourUserID]@um.edu.mo"、用戶IP地址或電話號碼(如註冊時有提供)將會被轉送及儲存於Duo
              Security在澳門以外的伺服器。而且，Duo Security有可能間接地得悉你是澳門大學的成員。
            </li>
            <li>當你離開大學後，你將失去使用Duo Security雙重認證服務的權利。</li>
            <li>
              一旦使用這個系統，表示用戶已知悉並同意以上說明及載於澳門大學章程內 (
              <Link href='https://www.um.edu.mo/zh-hant/privacy-policy/privacy-policy-statement/' target='_blank' rel="noreferrer">
                https://www.um.edu.mo/zh-hant/privacy-policy/privacy-policy-statement/
              </Link>
              ) 有關私隱政策聲明的條款及細則。
            </li>
            <li>
              <Link href='https://faq.icto.um.edu.mo/acceptable-use-policy-icto-computing-facilities-campus-network-and-internet/' target='_blank' rel="noreferrer">               
                資訊及通訊科技部的電腦設施，校園網絡及互聯網之使用守則 
              </Link>
          、
          <Link href='https://duo.com/legal/terms' target='_blank' rel="noreferrer">               
            Duo Security 服務合約
          </Link>
          {' '}
          和{' '}
          <Link href='https://duo.com/legal/privacy-notice-general' target='_blank' rel="noreferrer">               
            Duo Security 隱私權聲明
          </Link>
          。
            </li>
        </Typography>
      </Paper>
    </Container>
  );
}
