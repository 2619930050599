import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link'
import { Divider } from '@mui/material';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function QualtricsContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5, fontWeight: 400}} variant='subtitle1'>
                {t("qualtrics.header")}
                <Typography component='ul' variant='subtitle1' sx= {{fontWeight: 400}}>
                    <Trans i18nKey="qualtrics.header_c" components={[<li></li>,<li></li>]}></Trans>
                </Typography>
            </Typography>
            { apply_stat === "yes" ?
                <Box>
                    <Typography variant='subtitle1' sx={{mt: 1}}>
                        <Trans i18nKey="qualtrics.goto">
                        <Link href='https://umac.au1.qualtrics.com' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Divider sx={{ mt: 3, mb: 2}}></Divider>
                    <Typography>
                        <Trans i18nKey="qualtrics.moreinfo">
                            <Link href="https://library.um.edu.mo/resources/research_tools/qualtrics_en" target="_blank" rel="noreferrer" /></Trans>
                    </Typography>
                </Box> : null
            }
            {   apply_stat === "no" ?
                <Box>
                    <Divider sx={{ mt: 3}}></Divider>
                    <Typography variant='subtitle1' sx={{ mt: 2}}>
                        {t("qualtrics.notapply_header")}
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.remark")}
                        <Typography variant='subtitle1' component='ul' sx= {{fontWeight: 400}}>
                            <Trans i18nKey="qualtrics.remark" components={{
                                dot1: <li />,
                                dot2: <li />,
                                dot3: <li />,
                                dot4: <li />,
                                s: <strong />,
                                link1: <Link href='https://icto.um.edu.mo/help-desk-services/' target='_blank' rel="noreferrer" />
                            }}></Trans>
                        </Typography>
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2, mb: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography>
                        <Trans i18nKey="qualtrics.agreement">
                            <Link href='http://www.qualtrics.com/terms-of-service/' target='_blank' rel="noreferrer" />
                            <Link href='http://www.qualtrics.com/privacy-statement/' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ mt: 3}}>
                        <Trans i18nKey="qualtrics.notice" components={[<b></b>]}></Trans>
                        <Typography variant='subtitle1' component='ul' sx= {{fontWeight: 400}}>
                            <Trans i18nKey="qualtrics.notice_c" components={{
                                dot1: <li />,
                                dot2: <li />,
                                dot3: <li />,
                                link1: <Link href='https://www.um.edu.mo/privacy-policy/privacy-policy-statement/' target='_blank' rel="noreferrer" />
                            }}></Trans>
                        </Typography>
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(QualtricsContent);