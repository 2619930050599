import { useState } from "react";
import { useContext } from 'react';
import { Button, Snackbar } from "@mui/material";
import UserLicenseInfoContext, { EsetKey }  from "../utils/contexts/UserLicenseInfoContext"
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface indexprops {
    index: number,
    type: string
};

const CopyToClipboardButton = ( {index, type} : indexprops ) => {
    // console.log("print index  " + index)
    // console.log("print type  " + type)
    const userLicenseInfo = useContext(UserLicenseInfoContext);
    const keyArrs = userLicenseInfo?.LICENSE_ESET_SMART_RESULT?.esetKeyList;
    const notespwd = userLicenseInfo?.ACCOUNT_LOTUS_NOTES_RESULT

    let key : string = '';
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    
    if (type === "eset") {
      if ( keyArrs?.length !== undefined && keyArrs?.length > index) {
        key = keyArrs[index].key;
      } 
    }
    if (type === "lotusnotes") {
      if ( notespwd != undefined || notespwd != null) {
        key = notespwd;
      }
    }
    const handleClick = () => {
      setOpen(true);
        if (key !== '' ) {
          navigator.clipboard.writeText(key);
        }
    };
  
    return (
      <>
        <Button onClick={handleClick} color="primary" variant="contained">
          {t("button.copy")}
        </Button>
        <Snackbar
          message="Copied successfully"
          anchorOrigin={{ vertical: 'top', horizontal: "center" }}
          autoHideDuration={1200}
          onClose={() => setOpen(false)}
          open={open}
        />
      </>
    );
  };
  
  export default CopyToClipboardButton;
  