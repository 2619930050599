import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Container,
  Alert,
  Paper,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Typography,
} from '@mui/material';
import TwoFAAgreement from '../components/layout/TwoFAAgreement';
import {Navigate} from 'react-router-dom';
import '../styles/twofa.css';
import { useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UserLicenseInfoContext from '../utils/contexts/UserLicenseInfoContext';
import isDarkMode from '../utils/contexts/IsDarkMode';
import ErrorMsgDialog from '../components/HandleMsgDialog';
import TwofaDialog from '../components/layout/TwofaDialog';
import Link from '@mui/material/Link';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';
import { isUMApp, PageModule } from '@um/js-api';

interface ApplyTwoFAInput {
  agreedAgreement: boolean;
}

interface UMAppContextType {
  isApp: boolean;
}

const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};

const checkRight = () => {
  const licenseInfo = useContext(UserLicenseInfoContext);
  if (licenseInfo !== null && licenseInfo.Service.includes('ACCOUNT_TWOFA')) {
      // console.log("twofa check right return true")
      return true;
  } else {
    // console.log("twofa check right return false")
    return false;
  }
};

const TwoFA = () => {
  const { t } = useTranslation();
  if (isUMApp()) {
    PageModule.setTitle(`${t("twofa.pagetitle")}`)
  }
  document.title="Apply TwoFA"
  sessionStorage.removeItem("prevPath");
  const [loading, setLoading] = React.useState(false);
  const [agree, setAgree] = useState(false);
  const [queryParameters] = useSearchParams();
  const UserLicenseInfo = useContext(UserLicenseInfoContext);
  const isDark=useContext(isDarkMode);
  const [twofaDialogOpen, setTwofaDialogOpen] = useState(false);
  const [errorMsgDialogOpen, setErrorMsgDialogOpen] = useState(false);
  const [errorMsgDialogTitle, setErrorMsgDialogTitle] = useState(`${t("common.errtitle")}`);
  const [errorMsgDialogContent, setErrorMsgDialogContent] = useState(`${t("common.errmsg")}`);
  const [needReload, setNeedReload] = useState<boolean>(true);
  
  const handleMsgDialogOpen = (title: string, content: string) => {
    setErrorMsgDialogTitle(title);
    setErrorMsgDialogContent(content);
    setErrorMsgDialogOpen(true);
  };

  const handleTwofaDialogOpen = () => {
    if (agree) {
      setTwofaDialogOpen(true)
    }
  };

  const handleMsgDialogClose = () => {
    setErrorMsgDialogOpen(false);
  };

  const handleTwofaDialogClose = () => {
    setTwofaDialogOpen(false);
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ApplyTwoFAInput>({
    defaultValues: {
      agreedAgreement: false,
    },
  });

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  useEffect(() => {
    handleTwofaDialogOpen()
}, [agree]);

  const onApplyTwoFASubmit: SubmitHandler<ApplyTwoFAInput> = async (data) => {
    if (!agree) {
      throw new Error('Error: User not checked agree!');
    }
    
    if (!loading) {
      setLoading(true);
    }

    try {
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_CONTEXT_PATH}/api/twofa/login`,
      });
      if (response.status === 200 && response.data.data.authUrl !== undefined ) {
        var redirectURL = String(response.data.data.authUrl);
        // console.log("print URL in 2fa", redirectURL)
        setLoading(false);
        window.location = response.data.data.authUrl;
      } else {
        setLoading(false); 
        handleMsgDialogOpen(`${t("twofa.apply2fa")}`, response.data.errorMsg);
        // throw new Error('Error: Not able to apply 2FA');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error));
    }
  };

  async function completeApplicationAfterCallback(state: string, duo_code: string) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_CONTEXT_PATH}/api/twofa/callback`,
        data: { "state": state, "duo_code": duo_code},
      });
      if (response.status === 200) {     
      } else {
        handleMsgDialogOpen(`${t("twofa.callbackErr")}`, `${t("twofa.callbackErrMsg")}`);
        // throw new Error('Error: Not able to complete the application when calling call back API');
      }
    } catch (error) {
      handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error));
      console.log(error);
    }
  }
  if (!checkRight() && UserLicenseInfo !== null && UserLicenseInfo !== undefined) {
    // console.log("no right to change profile language");
    return <Navigate to="/error" replace />;
  } else if (UserLicenseInfo !== null && UserLicenseInfo !== undefined) {
    if (queryParameters.get('state') != null && queryParameters.get('duo_code') != null) {
      completeApplicationAfterCallback(
        String(queryParameters.get('state')),
        String(queryParameters.get('duo_code')),
      );
      return (
        <div>
          <Box
            sx={{
              pt: 1,
              display: 'flex',
              alignContent: 'center',
              // bgcolor: isDark? 'inherit':'#fff',
              // minHeight: '95vh'
            }}
          >
            <Container disableGutters sx={{ width: '98%', alignContent: 'center', px: 2 }}>
              <Box className='checkIcon' sx={{ width: '100%', alignContent: 'center' }}>
                <CheckCircleOutlineIcon
                  color='success'
                  sx={{ fontSize: 160, alignContent: 'center' }}
                />
                <Typography color={isDark ? "text.primary" : 'inherit'} sx={{ mt: 2}}>
                  {t("twofa.applied_statement")}
                </Typography>
              </Box>
              <Alert severity='success' sx={{ margin: 2 }} style={{ backgroundColor: isDark? '#212121' : '#F7F7ED' }}>
                <Trans i18nKey="twofa.applied_tip1">
                  <Link href='https://icto.um.edu.mo/wp-content/uploads/2022/02/Apply-2FA.pdf' target='_blank' rel="noreferrer" />
                  <Link href='https://icto.um.edu.mo/information-security/two-factor-authentication-2fa/' target='_blank' rel="noreferrer" />               
                </Trans>
              </Alert>
            </Container>
          </Box>
        </div>
      );
    } else if (UserLicenseInfo?.ACCOUNT_TWOFA) {
      return (
        <div>
          <Box
            sx={{
              pt: 1,
              display: 'flex',
              alignContent: 'center',
              // bgcolor: isDark? 'inherit':'#fff',
              // minHeight: '95vh'
            }}
          >
            <Container disableGutters sx={{ width: '98%', alignContent: 'center', px: 2 }}>
              <Box className='checkIcon' sx={{ width: '100%', alignContent: 'center' }}>
                <CheckCircleOutlineIcon
                  color='success'
                  sx={{ fontSize: 160, alignContent: 'center' }}
                />
                <Typography color={isDark ? "text.primary" : 'inherit'} sx={{ mt: 2}}>
                  {t("twofa.apply_success")}
                </Typography>
              </Box>
              <Alert severity='success' sx={{ margin: 2 }}>
                <Trans i18nKey="twofa.applied_tip1">
                  <Link href='https://icto.um.edu.mo/wp-content/uploads/2022/02/Apply-2FA.pdf' target='_blank' rel="noreferrer" />
                  <Link href='https://icto.um.edu.mo/information-security/two-factor-authentication-2fa/' target='_blank' rel="noreferrer" /> 
                </Trans>
              </Alert>
            </Container>
          </Box>
        </div>
      );
    } else {
      return (
        <div>
          <Box
            sx={{
              pt: 1,
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <TwoFAAgreement />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              pb: 3,
            }}
          >
            <form className='applyTwoFAForm' onSubmit={handleSubmit(onApplyTwoFASubmit)}>
              <Container
                sx={{ width: '100%', justifyContent: 'center', alignContent: 'center', px: 2 }}
              >
                <FormGroup
                  sx={{ width: '100%', justifyContent: 'center', alignContent: 'center', py: 1 }}
                >
                  <FormControlLabel
                    control={<Checkbox name='agreedAgreement' onChange={checkboxHandler}/>}
                    style={{ color: isDark ? '#ddd' : 'inherit'}} 
                    label={t("twofa.agreechkbox")}
                  />
                </FormGroup>

                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type='submit'
                    disabled={!agree || loading}
                    sx={{ minWidth: 300 }}
                  >
                    {t("button.apply")}
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                  </Box>
              </Container>
            </form>
          </Box>
          <ErrorMsgDialog open={errorMsgDialogOpen} onClose={handleMsgDialogClose} errorTitle={errorMsgDialogTitle} errorContent={errorMsgDialogContent} needReload={needReload}/>
          <TwofaDialog open={twofaDialogOpen} onClose={handleTwofaDialogClose} />
        </div>
      );
    }
  } else {
    return (
      <CircularProgress
                      size={35}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
    )
  }
};

export default withTranslation()(TwoFA);
