import * as React from 'react';
import {Divider, Box, Typography, Link} from '@mui/material';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function LibraryContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5,display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("library.header")}
            </Typography>
            {   apply_stat === "no" ?
                <Box>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography variant='subtitle1' component="ul">
                        <Trans i18nKey="library.agreement"
                            components={{
                                dot1: <li />,
                                dot2: <li />,
                                link1: <Link href="https://knowledge.exlibrisgroup.com/Alma/Product_Documentation/010Alma_Online_Help_(English)/010Getting_Started/020Security_and_Data_Privacy" target="_blank" rel="noreferrer" />
                            }}
                        ></Trans>
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(LibraryContent)