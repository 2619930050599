import * as React from 'react';
import { Divider, Link, Typography, Alert, Box } from '@mui/material';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function ZoomContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5, fontWeight: 400}} variant='subtitle1'>
                {t("zoom.header")}
                <Typography variant='subtitle1' component='ul' sx={{mt: 2}}>
                    <Trans i18nKey="zoom.header_content" components={{ dot: <li />}}></Trans>
                </Typography>
            </Typography>
            { apply_stat === "yes" ?
                <Box sx={{ mt: 4}}>
                    <Typography variant="subtitle1">
                        <Trans i18nKey="zoom.applied_body">
                            <Link href='https://umac.zoom.us/download' target='_blank' rel="noreferrer" />
                        </Trans>
                        <Typography variant='subtitle1' component='ul' sx={{ pb: 2}}>
                            <Trans i18nKey="zoom.applied_body_c" components={{
                                dot: <li />,
                                link1:<Link href='https://play.google.com/store/apps/details?id=us.zoom.videomeetings' target='_blank' rel="noreferrer" />,
                                link2: <Link href='https://itunes.apple.com/us/app/zoom-cloud-meetings/id546505307?mt=8' target='_blank' rel="noreferrer" />
                            }}>  
                            </Trans>
                        </Typography>
                        {t("zoom.applied_body2")}
                    </Typography>
                    <Divider sx={{mt: 3, mb:2}}></Divider>
                    <Typography>
                        <Trans i18nKey="zoom.moreinfo">
                            <Link href="https://icto.um.edu.mo/communication-collaboration/conferencing" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                </Box>: null
            }
            {   apply_stat === "no" ?
                <Box>
                    <Divider sx={{my: 2}}></Divider>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("zoom.notapply_header")}
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 400, mt: 2}}>
                    <Trans i18nKey="zoom.agreement">
                        <Link href="https://faq.icto.um.edu.mo/acceptable-use-policy-icto-computing-facilities-campus-network-and-internet/" target="_blank" rel="noreferrer" />
                        <Link href="https://zoom.us/terms/" target="_blank" rel="noreferrer" />
                        <Link href="https://zoom.us/privacy/" target="_blank" rel="noreferrer" />
                    </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                     {t("common.agree_note")}
                    </Typography>
                    <Typography variant='subtitle1' component='ul'>
                        <Trans i18nKey="zoom.notice" components={{
                            dot: <li />,
                            link1: <Link href="https://www.um.edu.mo/privacy-policy/privacy-policy-statement/" target="_blank" rel="noreferrer" />
                        }}>
                        </Trans>
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(ZoomContent);