import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './styles/global.css';
import UMApp from './UMApp';
import AuthSupport from './AuthSupport';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={`/${process.env.REACT_APP_CONTEXT_PATH}`}>
        <UMApp appOnly={false} authOnly={true}>
          <AuthSupport>
            <App />
          </AuthSupport>
        </UMApp>
    </BrowserRouter>
  </React.StrictMode>,
);
