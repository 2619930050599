import { Outlet } from 'react-router-dom';
import AcctAppBar from './AcctAppBar';
import { Box } from '@mui/material';
import { useContext } from 'react';
import isDarkMode from '../../utils/contexts/IsDarkMode';
import { UMAppContext } from '../../UMApp';
import { isUMApp, PageModule } from '@um/js-api';

interface UMAppContextType {
  isApp: boolean;
}
const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};

export default function Layout() {
  const isDark = useContext(isDarkMode);
  const bgcolorMobile = isDark && isUMApp() ? '#1c1b20' : isDark? '#515151' : 'transparent';
  const umappContext = useContext(UMAppContext);
  const showAppBar = umappContext.isApp;

  // console.log ("Is show ing the app bar   " + showAppBar)
  return (
    <Box>
      {!showAppBar ? <AcctAppBar /> : null}
      <Box
        sx={{
          bgcolor: { xs:  bgcolorMobile, sm: bgcolorMobile, md: isDark ? '#515151' : 'inherit' },
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
