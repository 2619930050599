import * as React from 'react';
import {Box,Typography, Link, Alert, Divider }  from '@mui/material';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function Office365ProPlusContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography variant='subtitle1' sx={{pt: 5, fontWeight: 600, display: (apply_stat === "no") ? 'inherit': 'none'}}>{t("proplus.notapply_header")}</Typography>
            <Typography sx={{pl: (apply_stat === "no") ? 2: 1, pt: (apply_stat === "no") ? 0: 5 }} variant='subtitle1'>
                <Trans i18nKey="proplus.header" components={{
                    dot: <li />,
                    link1: <Link href='https://faq.icto.um.edu.mo/guidelines-for-handling-confidential-information/' target='_blank' rel="noreferrer" />
                }}></Trans>
            </Typography>
            {
                apply_stat === "yes" ?
                <Box>
                    <Typography variant='subtitle1' sx={{ mt:4}}>
                        <Trans i18nKey="proplus.body">
                            <Link href='https://login.microsoftonline.com' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Divider sx={{mt:3, mb:2}}></Divider>
                    <Typography variant='subtitle1'>
                        <Trans i18nKey="proplus.moreinfo">
                            <Link href="https://faq.icto.um.edu.mo/tag/proplus/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                </Box>: null
            }
            {   apply_stat === "no" ?
                <Box>
                    <Divider sx={{ my: 2}}></Divider>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography variant='subtitle1'>
                    <Trans i18nKey="proplus.agreement">
                        <Link href="https://faq.icto.um.edu.mo/acceptable-use-policy-icto-computing-facilities-campus-network-and-internet/" target="_blank" rel="noreferrer" />
                        <Link href="http://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" target="_blank" rel="noreferrer" />
                        <Link href="http://www.microsoft.com/privacystatement/en-us/OnlineServices/Default.aspx" target="_blank" rel="noreferrer" />
                    </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                    {t("common.agree_note")}
                    </Typography>
                    <Typography variant="subtitle1" component='ul'>
                        <Trans i18nKey="proplus.note" components={{ dot: <li />}}></Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.notice")}
                    </Typography>
                    <Typography variant="subtitle1" component='ul'>
                        <Trans i18nKey="proplus.notice" 
                            components={{ 
                                dot: <li />, 
                                link1:<Link href="https://www.um.edu.mo/privacy-policy/privacy-policy-statement/" target="_blank" rel="noreferrer" /> 
                            }}>
                        </Trans>
                    </Typography>
                    <Alert severity='info' sx={{ mt: 2}}><Trans i18nKey="proplus.info" components={{ s: <strong />}}></Trans></Alert>
                </Box>: null
            }
        </Box>
    );
};

export default withTranslation()(Office365ProPlusContent);