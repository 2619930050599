import { AccountModule, isUMApp, AppModule, PageModule } from '@um/js-api';
import axios from 'axios';
import { createContext, ReactElement, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import isDarkMode from './utils/contexts/IsDarkMode';
import { useMediaQuery } from '@mui/material';
import i18next from 'i18next';

interface UMAppContextType {
  isApp: boolean;
}

const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};
export const UMAppContext = createContext<UMAppContextType>(contextValue);

// const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');

function UMApp(props: { appOnly: boolean; authOnly: boolean; children: ReactElement | null }) {
  const [ready, setReady] = useState(false);

  const [isDark, setIsDark] = useState<boolean>(false);

  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
  // console.log("Get Media Query Mode (Browser):" + isDarkModeEnabled)

  useEffect(() => {
    if (!isUMApp()) {
      // console.log("Not UM APP")
      setIsDark(isDarkModeEnabled ? true : false);
      // console.log(isDark)
      // console.log("=================")
      return;
    }
    AppModule.getAppTheme()
      .then((data) => {
        // console.log("Get in App Theme: " + data)
        // console.log(data)
        // console.log(data.theme)
        // console.log(data.isDarkMode);
        setIsDark((data.theme === 'system') ? data.isDarkMode : data.theme === 'dark')
      })
      .catch((reason) => console.log(reason));
    AppModule.getAppLanguage().then((data) => {
      // console.log("Get App's Language: " + data);
      if (data === 'zh_TW') {
        i18next.changeLanguage('zh');
      } else {
        i18next.changeLanguage('en');
      }
    });
    PageModule.setTitle("");
    axios.interceptors.request.use(async (config) => {
      const isLogined = await AccountModule.isLogin();
      if (!isLogined) throw new Error('UMApp not loged in (interceptor).');
      const { access_token } = await AccountModule.getToken();
      config.headers.Authorization = `Bearer ${access_token}`;
      return config;
    });
    AccountModule.isLogin()
      .then((logined) => {
        if (logined) return AccountModule.getToken();
        throw new Error('UMApp not loged in (init).');
      })
      .then((token) => {
        setReady(true);
      })
      .catch((reason) => console.log(reason));
  }, []);

  if (isUMApp()) {
    if (ready) {
      // console.log("Is UMAPP true and is ready")
      return <isDarkMode.Provider value={isDark}>{props.children}</isDarkMode.Provider>;
    } else {
      return props.authOnly ? (
        <></>
      ) : (
        <isDarkMode.Provider value={isDark}>
          <UMAppContext.Provider value={contextValue}>{props.children}</UMAppContext.Provider>
        </isDarkMode.Provider>
      );
    }
  } else {
    return props.appOnly ? (
      <></>
    ) : (
      <isDarkMode.Provider value={isDark}>
        <UMAppContext.Provider value={contextValue}>{props.children}</UMAppContext.Provider>
      </isDarkMode.Provider>
    );
  }
}

export default UMApp;
