import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { ThemeProvider } from '@emotion/react';
import axios, { AxiosError } from 'axios';

import Layout from './components/layout/Layout';
import Root from './pages/Root';
import MyLicense from './pages/MyLicense';
import ChangePassword from './pages/ChangePassword';
import TwoFA from './pages/TwoFA';
import ChangeProfileLanguage from './pages/ChangeProfileLanguage';
import ErrorPage from './pages/Error'

import AccountStatusContext, {AccountStatus} from './utils/contexts/AccountStatusContext';
import UserInfoContext, { UserInfo } from './utils/contexts/UserInfoContext';
import UserLicenseInfoContext, { UserLicenseInfo } from './utils/contexts/UserLicenseInfoContext';
import isDarkMode from './utils/contexts/IsDarkMode';

import ErrorMsgDialog from './components/HandleMsgDialog';
import { lightTheme, darkTheme } from './utils/MyTheme';
import './i18n';

function App() {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [accountStatus, setAccountStatus] = useState<AccountStatus | null>(null);
  const [userLicenseInfo, setUserLicenseInfo] = useState<UserLicenseInfo | null>(null);
  const location = useLocation();
  const [errorMsgDialogOpen, setErrorMsgDialogOpen] = useState(false);
  const [errorMsgDialogTitle, setErrorMsgDialogTitle] = useState("Sorry!");
  const [errorMsgDialogContent, setErrorMsgDialogContent] = useState("The service is temporarily unavailable and please try again later. We apologize for any inconveniences caused.");
  const isDark = useContext(isDarkMode)
  const themeMode = isDark ? darkTheme : lightTheme
  const [needReload, setNeedReload] = useState<boolean>(true);

  const handleMsgDialogOpen = (title: string, content: string) => {
    setErrorMsgDialogTitle(title);
    setErrorMsgDialogContent(content);
    setErrorMsgDialogOpen(true);
  };

  const handleMsgDialogClose = () => {
    setErrorMsgDialogOpen(false);
  };

  useEffect(() => {
    if (userInfo === null && location.pathname !== `${process.env.REACT_APP_CONTEXT_PATH}/error`) {
      axios
        .get(`${process.env.REACT_APP_CONTEXT_PATH}/api/userinfo`)
        .then((resp) => {
          setUserInfo(resp.data as UserInfo);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (accountStatus === null && location.pathname !== `${process.env.REACT_APP_CONTEXT_PATH}/error`) {
      axios
        .get(`${process.env.REACT_APP_CONTEXT_PATH}/api/account/get_account_status`)
        .then((resp) => {
          setAccountStatus(resp.data.data as AccountStatus);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    }
  }, [accountStatus]);

  useEffect(() => {
    let respData : UserLicenseInfo;
    if (userLicenseInfo === null && location.pathname !== `${process.env.REACT_APP_CONTEXT_PATH}/error`) {
      axios
        .get(`${process.env.REACT_APP_CONTEXT_PATH}/api/account/get_account_info`)
        .then((resp) => {
          if (resp.status === 200) {
            // console.log("========= Get License data from API in APP.tsx========")
          }
          //console.log(resp.data.data);
          //console.log(resp.data.role.services);
          respData=resp.data.data;
          respData['Service'] = (resp.data.role.services);
          //console.log(respData);
          //console.log("========= End Getting data from API ========")
          setUserLicenseInfo(respData as UserLicenseInfo);
        })
        .catch((error: AxiosError) => {
          handleMsgDialogOpen("Service Error", "Cannot get user's License info. Apply License and 2FA service is affected.")
          console.log(error);
        });
    }
  }, [userLicenseInfo]);

  return !userInfo ? (
    // <></>
    <Routes>
      <Route path='/' element={<Root />} /> 
      <Route path='home' element={<Root />} />
      <Route path='error' element={<ErrorPage />} />
    </Routes>
  ) : (
    <ThemeProvider theme={themeMode}>
    <UserInfoContext.Provider value={userInfo}>
      <AccountStatusContext.Provider value={accountStatus}>
        <UserLicenseInfoContext.Provider value={userLicenseInfo}>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index={true} element={<Root />} />
              <Route path='home' element={<Root />} />
              <Route path='error' element={<ErrorPage />} />
              <Route path='mylicense' element={<MyLicense />} />
              <Route path='changepassword' element={<ChangePassword />} />
              <Route path='changeprofilelanguage' element={<ChangeProfileLanguage />} />
              <Route path='twofa' element={<TwoFA />} />
              <Route path='*' element={<Navigate to='/error' />} />
            </Route>
          </Routes>
          <ErrorMsgDialog open={errorMsgDialogOpen} onClose={handleMsgDialogClose} errorTitle={errorMsgDialogTitle} errorContent={errorMsgDialogContent} needReload={needReload}/>
        </UserLicenseInfoContext.Provider>
      </AccountStatusContext.Provider>
    </UserInfoContext.Provider>
    </ThemeProvider>
  );
}

export default App;
